import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { ipStackMe, handleRedirect } from "../services/ipStack"

const useIpStack = () => {
  useEffect(() => {
    const cookies = new Cookies();
    const geolocationCookie = cookies.get('geolocation');
    const developerSession = cookies.get('developerSession');
    if (!developerSession) {
      if (!geolocationCookie) {
        ipStackMe(cookies);
      }
      handleRedirect(geolocationCookie, cookies);
    }
  }, []);
};

export default useIpStack;
