import React from "react"
import Modal from 'react-responsive-modal';
import YouTube from "react-youtube"
import styles from "../styles/scss/components/customModal.module.scss"

const CustomModal = ({ openVideo, setOpenVideo, youtubeVideoId, rel = 0, videoUrl = '' }) => {
    return (

        openVideo &&
        <Modal
            open={openVideo}
            onClose={() => {
                setOpenVideo(false)
            }}
            classNames={{
                modal: styles.customModal,
                closeIcon: styles.closeIcon,
                closeButton: styles.closeButton,
                overlay: styles.modalOverlay,
                modalContainer: styles.modalContainer,
                root: styles.videoModal,
            }}
            center
        >

            {
                youtubeVideoId &&
                <YouTube
                    videoId={youtubeVideoId}
                    opts={{
                        height: "400",
                        width: "100%",
                        playerVars: {
                            autoplay: 1,
                            rel: rel,
                        },
                    }}
                />
            }
      
            {
                videoUrl && videoUrl !== '' && videoUrl !== 'NOVIDEO' &&
                <video
                    preload="auto"
                    controls
                    autoPlay
                    muted
                    className={styles.video}
                >
                    <source
                        src={`https://s3.us-west-2.amazonaws.com/files.wd40.com/CustomersVideos/${videoUrl}`}
                        type="video/mp4"
                    />
                </video>
            }

        </Modal>


    )
}

export default CustomModal
