import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import cn from "classnames"

// Data
import data from "../data/newHeader.json"

// Components
import Search from "./search"

// Services
import { supSub } from "../services/util"

// Styles
import styles from "../styles/scss/components/newMenu.module.scss"

const isHidden = function isHidden(el) {
    if (el && typeof window !== "undefined") {
        const style = window.getComputedStyle(el)
        return style.display === "none"
    }
    return false
}

const NewMenu = () => {
    // Refs
    const nodeRef = React.useRef(null)

    // States
    const [items, setItems] = React.useState({})
    const [activeTab, setActiveTab] = React.useState("")

    // Effects
    React.useEffect(() => {
        const handleClick = function handleClick(e) {
            if (typeof document !== "undefined") {
                let hamElem = document.querySelector("." + styles.menuHamburger)
                // If non-mobile or no children, do nothing
                if (isHidden(hamElem)) {
                    return
                }
                if (
                    nodeRef.current !== null &&
                    !nodeRef.current.contains(e.target)
                ) {
                    setItems({})
                }
            }
        }

        if (typeof document !== "undefined") {
            document.addEventListener("mousedown", handleClick, false)
        }

        return () => {
            if (typeof document !== "undefined") {
                document.removeEventListener("mousedown", handleClick, false)
            }
        }
    }, [])

    // Handlers
    const toogleItems = React.useCallback(
        (e, slug) => {
            if (typeof document !== "undefined") {
                e.preventDefault()
                e.stopPropagation()

                const item = data.find(x => x.slug === slug)
                const hamElem = document.querySelector(
                    "." + styles.menuHamburger
                )

                // If non-mobile or no children, navigate to url
                if (isHidden(hamElem) || item.children.length === 0) {
                    navigate(item.url)
                    return
                }

                const newItems = { ...items }
                const value = newItems[slug] ? false : true
                newItems[slug] = value

                setItems(newItems)
            }
        },
        [items]
    )

    // Memos
    const dataItems = React.useMemo(() => {
        return data.filter(dataItem => !dataItem.mobileOnly)
    }, [])

    useEffect(() => {
        if (typeof window !== "undefined") {
            setActiveTab(window.location.pathname)
        }
    }, [activeTab])

    return (
        <div className={styles.menuFull} ref={nodeRef}>
            {/* Logo */}
            <div className={styles.logoMenu}>
                <Link to="/" tabIndex={0}>
                    <img
                        className={styles.menuLogo}
                        src="/img/logo.svg"
                        alt="WD-40 Logo"
                        draggable="false"
                    />
                </Link>
            </div>

            {/* Right Part */}
            <div className={styles.rightPart}>
                <div className={cn(styles.menuItems)}>
                    {dataItems.map((item, key) => (
                        <div
                            id={"menu-" + item.id}
                            className={styles.menuItem}
                            key={key}
                        >
                            {/* Top Link */}
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={e => {
                                    if (e.key !== "Tab") {
                                        toogleItems(e, item.slug)
                                    }
                                    
                                }}
                                onClick={e => toogleItems(e, item.slug)}
                                className={
                                    activeTab === "/" + item.slug + "/"
                                        ? cn(styles.pageActive)
                                        : cn(styles.menuItemMainLink)
                                }
                                {...item.data}
                            >
                                <span
                                    className={styles.spanWithSupSub}
                                    dangerouslySetInnerHTML={{
                                        __html: supSub(item.title),
                                    }}
                                />
                            </div>

                            {/* Children */}
                            {item.children.length > 0 && (
                                <div
                                    className={cn(
                                        (item.children[0] &&
                                            item.children[0].children.length) ||
                                            (item.children[1] &&
                                                item.children[1].children
                                                    .length)
                                            ? styles.menuSubAlt
                                            : "",
                                        styles.menuSub,
                                        items[item.slug] && styles.active
                                    )}
                                >
                                    {item.children.map((subItem, subKey) => (
                                        <React.Fragment key={subKey}>
                                            {subItem.children.length > 0 ? (
                                                <div
                                                    key={subKey}
                                                    className={cn(
                                                        styles.subItemsMenu
                                                    )}
                                                >
                                                    <div
                                                        className={cn(
                                                            styles.subItemsMenuContainer
                                                        )}
                                                    >
                                                        <a
                                                            key={subItem.id}
                                                            href={subItem.url}
                                                            target={
                                                                subItem.openInNewWindow
                                                                    ? "_new"
                                                                    : "_self"
                                                            }
                                                            className={cn(
                                                                {
                                                                    "d-sm-none":
                                                                        subItem.mobileOnly,
                                                                },
                                                                {
                                                                    [`${styles.subMenuMobielOnly}`]: subItem.mobileOnly,
                                                                }
                                                            )}
                                                        >
                                                            <span
                                                                className={
                                                                    styles.spanWithSupSub
                                                                }
                                                                dangerouslySetInnerHTML={{
                                                                    __html: supSub(
                                                                        subItem.title
                                                                    ),
                                                                }}
                                                            />
                                                        </a>

                                                        {subItem.children.map(
                                                            (
                                                                lastChild,
                                                                lastKey
                                                            ) => (
                                                                <a
                                                                    key={
                                                                        lastKey
                                                                    }
                                                                    href={
                                                                        lastChild.url
                                                                    }
                                                                    target={
                                                                        lastChild.openInNewWindow
                                                                            ? "_new"
                                                                            : "_self"
                                                                    }
                                                                    className={cn(
                                                                        {
                                                                            "d-sm-none":
                                                                                lastChild.mobileOnly,
                                                                        },
                                                                        {
                                                                            [`${styles.subMenuMobielOnly}`]: lastChild.mobileOnly,
                                                                        }
                                                                    )}
                                                                >
                                                                    <span
                                                                        className={
                                                                            styles.spanWithSupSub
                                                                        }
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: supSub(
                                                                                lastChild.title
                                                                            ),
                                                                        }}
                                                                    />
                                                                </a>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <a
                                                    key={subItem.id}
                                                    href={subItem.url}
                                                    target={
                                                        subItem.openInNewWindow
                                                            ? "_new"
                                                            : "_self"
                                                    }
                                                    className={cn(
                                                        {
                                                            "d-sm-none":
                                                                subItem.mobileOnly,
                                                        },
                                                        {
                                                            [`${styles.subMenuMobielOnly}`]: subItem.mobileOnly,
                                                        }
                                                    )}
                                                >
                                                    <span
                                                        className={
                                                            styles.spanWithSupSub
                                                        }
                                                        dangerouslySetInnerHTML={{
                                                            __html: supSub(
                                                                subItem.title
                                                            ),
                                                        }}
                                                    />
                                                </a>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Search */}
                <Search onlySearch={true} />
            </div>
        </div>
    )
}

export default React.memo(NewMenu)
