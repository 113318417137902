import React, { Component } from 'react';
import styles from '../styles/scss/components/search.module.scss'
import cn from 'classnames';
import { Link } from 'gatsby'
import Imgix from 'react-imgix';

export default class Search extends Component {

    render() {
        return (
            <> {!this.props.onlySearch ?
                <div className={styles.searchContainer}>
                    <div className={cn(styles.searchItem, styles.iconSearch)}>
                        <a href='/search/'>
                            <i className='fa fa-search' />
                        </a>
                    </div>
                    <div className={cn(styles.searchItem)}>
                        <Link to='/blog/'>BLOG</Link>
                    </div>
                    <div className={cn(styles.searchItem)}>
                        <Link to="/signup/">EMAIL SIGNUP</Link>
                    </div>
                </div>
                : <div className={styles.newSearchContainer}>
                    <div className={cn(styles.newSearchIcon)}>
                        <a href='/search/' tabIndex={0} aria-label='Search Button'>
                            <Imgix src='/img/newSearch.png' height={34} width={34} htmlAttributes={{alt: 'Magnifying Glass'}} />
                        </a>
                    </div>
                </div>}
            </>
        );
    }
}
