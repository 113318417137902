import React, { Component } from "react"
import cn from "classnames"
import groupBy from "lodash/groupBy"
import data from "../data/newfooter.json"
import { supSub } from "../services/util"
import Tooltip from './tooltip';
import styles from "../styles/scss/components/newFooter.module.scss"

class FooterComponent extends Component {
    render() {
        const rows = groupBy(data, "row");
        const columns = groupBy(data, "column");

        const socialAnchors = [
            {
                url: "https://www.facebook.com/OriginalWD40/",
                className: "fa fa-facebook-official",
                label: "Facebook"
            },
            {
                url: "https://twitter.com/originalwd40?lang=en",
                className: "fa-brands fa-x-twitter",
                label: "Twitter"
            },
            {
                url: "https://www.instagram.com/wd40brand/?hl=en",
                className: "fa fa-instagram",
                label: "Instagram"
            },
            {
                url: "https://www.youtube.com/user/OriginalWD40",
                className: "fa fa-youtube-play",
                label: "Youtube"
            },
            {
                url: "https://www.tiktok.com/@wd40brand",
                className: "fa fa-tiktok",
                label: "TikTok"
            }
        ]

        const secondColumnLinks = [
            {
                url: "/products/",
                slug: "products",
                title: "Products",
                openInNewWindow: false
            },
            {
                url: "/products/?category=multi-use",
                slug: "products-category-multi-use",
                title: "WD-40® Multi-Use Product",
                className: styles.productSubAnchors,
                openInNewWindow: false
            },
            {
                url: "/products/?category=specialist",
                slug: "products-category-specialist",
                title: "WD-40 Specialist®",
                className: styles.productSubAnchors,
                openInNewWindow: false
            },
            {
                url: "/products/?category=bike",
                slug: "products-category-bike",
                title: "WD-40 Specialist® BIKE",
                className: styles.productSubAnchors,
                openInNewWindow: false
            },
            ...columns[1]];

        const thirdColumnLinks = [
            ...columns[2]]

        const fourthColumnLinks = [
            ...columns[3]]

        const footerLeftColumn = [
            ...secondColumnLinks, ...columns[2]
        ]
        const printAnchor = (item, key) => {
            return (
                <div
                    className={cn(styles.anchors)}
                    key={key}>
                    <a
                        key={item.slug}
                        href={item.url}
                        target={
                            item.openInNewWindow
                                ? "_new"
                                : "_self"
                        }

                        className={cn(
                            styles[
                            item.mobileOnly
                                ? "link-mobile-only"
                                : ""
                            ]
                        )}

                        dangerouslySetInnerHTML={{
                            __html: supSub(item.title),
                        }}
                    />
                </div>
            )
        }

        const printSocialAnchor = (item, key) => {
            switch (item.label) {
                case "TikTok":
                    return (
                        <a key={key}
                            aria-label={item.label}
                            href={item.url}
                            target="_new"
                            className={item.className}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={20} height={24}>
                                <path fill="currentColor" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                            </svg>
                        </a>
                    )
                case "Twitter":
                    return (
                        <a key={key}
                            aria-label={item.label}
                            href={item.url}
                            target="_new"
                            className={item.className}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20} height={24}>
                                <path fill="currentColor" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                            </svg>
                        </a>
                    )
                default:
                    return (
                        <a key={key}
                            aria-label={item.label}
                            href={item.url}
                            target="_new">
                            <i className={item.className} />
                        </a>
                    )
            }
        }

        const productLinks = (
            <div className={cn(styles.anchors, styles.productAnchors)}>
                {secondColumnLinks.map((item, key) => (
                    printAnchor(item, key)
                ))}
            </div>
        )

        const socialLinks = (
            <div className={cn(styles.socialAnchors)}>
                {socialAnchors.map((item, key) => (printSocialAnchor(item, key)))}
            </div>
        )

        return (
            <>
                <div className={styles.footer}>
                    <div className={cn(styles.footerWrapper)}>
                        <div className={cn(styles.firstColumn)}>
                            <a href="/">
                                <img
                                    className={styles.footerLogo}
                                    src="/img/logo.svg"
                                    height="100"
                                    alt="WD-40 Logo"
                                />
                            </a>
                        </div>
                        <div className={cn(styles.secondColumn)}>
                            {productLinks}
                        </div>
                        <div className={cn(styles.thirdColumn)}>
                            {thirdColumnLinks.map((items, key) => printAnchor(items, key))}
                            <div>
                                <p className={cn(styles.paragraph)}>Follow Us:</p>
                                {socialLinks}
                            </div>
                        </div>
                        <div className={cn(styles.fourthColumn)}>
                            {fourthColumnLinks.map((items, key) => printAnchor(items, key))}
                        </div>
                        <div className={cn(styles.fifthColumnn)}>
                            <a className={cn(styles.link)}
                                href='/contact-us'
                                target="_self">
                                Contact Us
                            </a>
                            <p className={cn(styles.textBox)}>
                                WD-40 Customer Relations 1.888.324.7596 <br />
                                <br />
                                Mailing Address: <br />
                                WD-40 Customer Relations<br />
                                P.O. Box 308 <br />
                                Budd Lake, NJ, 07828
                            </p>
                            <p className={cn(styles.copyright)}>
                                &copy; {new Date().getFullYear()} WD-40
                            </p>

                        </div>
                    </div>

                    <div className={cn(styles.footerMobileWrapper)}>
                        <div className={cn(styles.firstRow)}>
                            <a href="/">
                                <img
                                    className={styles.footerLogo}
                                    src="/img/logo.svg"
                                    height="100"
                                    alt="WD-40 Logo"
                                />
                            </a>
                        </div>
                        <div className={cn(styles.secondRow)}>
                            <div className={cn(styles.firstColumn)}>
                                {footerLeftColumn.map((items, key) => printAnchor(items, key))}
                            </div>
                            <div className={cn(styles.secondColumn)}>
                                {fourthColumnLinks.map((items, key) => printAnchor(items, key))}
                                <p className={cn(styles.paragraph)}>
                                    Follow Us:
                                </p>
                                {socialLinks}
                                <a className={cn(styles.link)}
                                    href='/contact-us'
                                    target="_self">
                                    Contact Us
                                </a>
                                <p className={cn(styles.textBox)}>
                                    WD-40 Customer Relations 1.888.324.7596 <br />
                                    <br />
                                    Mailing Address: <br />
                                    WD-40 Customer Relations<br />
                                    P.O. Box 308 <br />
                                    Budd Lake, NJ, 07828
                                </p>
                                <p className={cn(styles.copyright)}>
                                    &copy; {new Date().getFullYear()} WD-40
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={cn(styles.footerLastRow)}>
                    {rows[2].map(item =>
                        item.tooltip ?
                            <Tooltip
                                key={item.slug}
                                content={(
                                    <div classname={styles.tooltipText} dangerouslySetInnerHTML={{
                                        __html: supSub(item.tooltip),
                                    }} />
                                )}
                                direction={item.title === 'About WD-40 Brand Uses' ? "down-start" : "down-end"}
                                tagName='span'
                            >
                                <p
                                    href={item.url}
                                    dangerouslySetInnerHTML={{
                                        __html: supSub(item.title),
                                    }}
                                />
                            </Tooltip>
                            : <a
                                key={item.slug}
                                href={item.url}
                                target={item.openInNewWindow ? "_new" : "_self"}
                                className={
                                    styles[
                                    item.mobileOnly ? "link-mobile-only" : ""
                                    ]
                                }
                                dangerouslySetInnerHTML={{
                                    __html: supSub(item.title),
                                }}
                            />

                    )}
                </div>
            </>
        )
    }
}

export default FooterComponent
