import React, { Component } from 'react';
import styles from '../styles/scss/components/text.module.scss';
import cn from 'classnames';

export default class Index extends Component {
    render() {
        const { value, style, blue, alignLeft, children, className } = this.props;
        if (!value && !children) return null;
        return (
            <div className={cn(styles.text, className, { [styles.blue]: blue, [styles.alignLeft]: alignLeft })} style={style}>
                {value || children}
            </div>
        );
    }
}
