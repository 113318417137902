import React from 'react';
import cn from 'classnames';
import { Visible,  } from 'react-grid-system';

// Components
import NewMenu from './newMenu';
import NewMenuMovil from './newMenuMovil';

// Styles
import styles from '../styles/scss/components/header.module.scss';

const NewHeader = ({props}) => {
  return (
    <div className={cn(styles.header, styles.blueMenu)} {...props} >
      <Visible xs sm md>
        <NewMenuMovil />
      </Visible>

      <Visible lg xl xxl> 
        <NewMenu />
      </Visible>
    </div>
  );    
}

export default React.memo(NewHeader);