import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function SEO({ page, indexableFollowed }) {

  var meta = []

  if (page.seo !== undefined && page.seo !== null) {
    if (page.seo.description !== undefined) {
      meta.push({
        name: 'description',
        content: page.seo.description
      })
    }
    if (page.seo.twitterCard !== undefined) {
      meta.push({
        name: 'twitter:creator',
        content: 'WD-40'
      })
      if (page.seo.title !== undefined) {
        meta.push({
          name: 'twitter:title',
          content: page.seo.title
        })
      }
      if (page.seo.description !== undefined) {
        meta.push({
          name: 'twitter:description',
          content: page.seo.description
        })
      }
    }
  }

  meta = meta.concat(
    page.tags.length > 0
      ? {
        name: `keywords`,
        content: page.tags.join(`, `),
      }
      : []
  );

  return (
    <Helmet
      title={page.seo !== null ? page.seo.title : 'WD-40'}
      titleTemplate={`%s | WD-40`}
      meta={meta}>

      {!indexableFollowed &&
        <meta name="robots" content="none" />
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  page: null,
  meta: [],
  indexableFollowed: true
}


SEO.propTypes = {
  page: PropTypes.object.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object),
  indexableFollowed: PropTypes.bool,
}

export default SEO
