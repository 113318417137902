import React, { Component } from 'react';
import styles from '../styles/scss/components/imageBlock.module.scss'
import cn from 'classnames';
import { Link } from 'gatsby';

export default class Index extends Component {

    getURLContent(url, description) {
        if (url === undefined) {
            return <></>
        }
        if (url === '#' && description) {
            return <span>{description}</span>
        } else if (url.indexOf('http') > -1 && description) {
            return <a href={url} target="_new"><span>{description}</span></a>
        } else if (url.indexOf('http') > -1 && !description) {
            return <a href={url} target="_new"><span>&nbsp;</span></a>
        } else if (url && description) {
            return <Link to={url}><span>{description}</span></Link>
        } else {
            return <Link to={url}><span>&nbsp;</span></Link>
        }
    }

    render() {
        let { url, image, title, description, titleClass, containerClass, imageClass, bodyClass, children, id, hoverEnabled = false } = this.props;
        return (
            <div id={id} className={cn("imageBlock", hoverEnabled ? styles.containerChildren_hoverEnabled : styles.containerChildren, containerClass)}>
                <div style={{ backgroundImage: `url(${image})` }} className={cn("image", styles.container, imageClass || styles.defaultImage)}>
                    {title && <p className={titleClass || styles.defaultTitle}>{title}</p>}
                    {url && <>{this.getURLContent(url, description)}</>}
                </div>
                {children && <div className={cn(styles.children, bodyClass || styles.defaultBody)}>{children}</div>}
            </div>
        );
    }
}
