import { Helmet } from "react-helmet"
import React from "react"
import PropTypes from "prop-types"

import NewFooter from "../components/newFooter"
import NewHeader from "../components/newHeader"
import useIpStack from '../hooks/useIpStack';

import "../styles/bootstrap/bootstrap.min.css"
import "../styles/css/main.css"
import "../styles/font-awesome/css/font-awesome.min.css"

import { setConfiguration } from "react-grid-system"

setConfiguration({ gutterWidth: 20 })

const NewLayout = ({ children }) => {
    useIpStack();
    return (
        <>
            <Helmet>
                <meta name="format-detection" content="telephone=no" />
                {/* Price Spider */}
                <meta name="ps-key" content="1675-59d8056be4625d01baa9abaa" />
                <meta name="ps-country" content="US" />
                <meta name="ps-language" content="en" />
                {/* CSS */}
                <link
                    rel="stylesheet"
                    href="//maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossOrigin="anonymous"
                />
                {/* Add the US hreflang tag */}
                <link rel="alternate" hreflang="en-us" href="https://www.wd40.com" />
                
                {/* Add the UK hreflang tag */}
                <link rel="alternate" hreflang="en-gb" href="https://wd40.co.uk" />
                
            </Helmet>
            <div>
                <NewHeader />
                <main>{children}</main>
                <NewFooter />
            </div>
        </>
    )
}

NewLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default NewLayout
