import React, { Component } from 'react';
import styles from '../styles/scss/components/button.module.scss'
import cn from 'classnames';

export default class Index extends Component {
    handleClick(e) {
        const { onClick } = this.props;
        if (onClick) onClick(e);
    }
    render() {
        const { text, url, big, blue, newBlue, red, small, className, pdf, submit, target, disabled = false } = this.props;
        if (!text) return null;
        return (!submit || pdf) ? (
            <a
                href={url || '#'}
                onClick={this.handleClick.bind(this)}
                target={target}
                className={cn(styles.button, className, { [styles.big]: big, [styles.blue]: blue, [styles.small]: small, [styles.newBlue]: newBlue, [styles.red]: red })}>
                {text}
            </a>
        ) : (
            <button
                disabled={disabled}
                type='submit'
                onClick={this.handleClick.bind(this)}
                className={cn(styles.button, className, { [styles.disabled]: disabled, [styles.big]: big, [styles.blue]: blue, [styles.small]: small, [styles.newBlue]: ((disabled === false) ? newBlue : ''), [styles.red]: red })}>
                {text}
            </button>
        );
    }
}
