import React, { Component } from "react"
import CustomModal from "./customModal"
import Title from "./title"
import Text from "./text"
import styles from "../styles/scss/components/videoBlock.module.scss"
import cn from "classnames"
export default class Index extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            openVideo: false,
        }
    }
    render() {
        let {
            id,
            customVideo = false,
            videoUrl = '',
            youtubeVideoId,
            image,
            title,
            time,
            description,
            hideBody,
            containerClass,
            imageClass,
            bodyClass,
            onPlay,
            rel,
        } = this.props
        return (
            <div
                className={cn(
                    styles.container,
                    containerClass || styles.defaultContainer
                )}
            >
                <div
                    style={{ backgroundImage: `url(${image})` }}
                    className={cn(
                        styles.image,
                        imageClass || styles.defaultImage
                    )}
                >
                    {(youtubeVideoId || customVideo) && (
                        <a
                            href="javascript:;"
                            onClick={() => {
                                this.setState({ openVideo: true })
                                if (onPlay) {
                                    onPlay(id)
                                }
                            }}
                        >
                            <div className={styles.circlePlay}>
                                <i className="fa fa-play" />
                            </div>
                        </a>
                    )}
                </div>
                {(youtubeVideoId && !customVideo) && (
                    <CustomModal
                        rel={rel}
                        openVideo={this.state.openVideo}
                        setOpenVideo={() => this.setState({ openVideo: false })}
                        youtubeVideoId={youtubeVideoId}
                    />
                )}
                
                {(customVideo) && (
                    <CustomModal
                        rel={rel}
                        openVideo={this.state.openVideo}
                        setOpenVideo={() => this.setState({ openVideo: false })}
                        videoUrl={videoUrl}
                    />
                )}
                {!hideBody && (
                    <div className={cn(styles.body, bodyClass)}>
                        {title && (
                            <Title
                                className={styles.title}
                                value={title}
                                size={20}
                                alignLeft
                            />
                        )}
                        {title && time && (
                            <Text value={time} className={styles.time} />
                        )}
                        {description && (
                            <Text className={styles.text}>{description}</Text>
                        )}
                    </div>
                )}
            </div>
        )
    }
}
