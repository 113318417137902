const setCookie = (cookies, value, name) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    cookies.set(name, value, { expires: expirationDate });
}

const getCountryCode = (code) => {
    if (code === undefined) return 'NO-REDIRECT';
    const upperCaseCode = code.toUpperCase();
    const latamCodes = ['AR', 'BO', 'BR', 'CL', 'CO', 'CR', 'CU', 'DO', 'EC', 'GT', 'HN', 'MX', 'NI', 'PA', 'PE', 'PY', 'SV', 'UY', 'VE'];
    return upperCaseCode === 'CA' ? 'NA' : latamCodes.includes(upperCaseCode) ? 'LATAM' : 'NO-REDIRECT';
};

export const handleRedirect = (code, cookies) => {
    const path = window.location.pathname;
    const disabledRedirectPaths = [
        '/proboard/',
        '/techs-and-trades/',
    ]
    const isDisabledPath = disabledRedirectPaths.includes(path);
    const params = new URLSearchParams(window.location.search);
    const isDeveloper = params.get('developer');
    const redirectStatus = params.get('redirect') || '';
    const countryCode = getCountryCode(code)
    const redirectCodes = {
        'NA': 'https://wd40.ca/',
        'LATAM': 'https://wd40.lat/en/'
    };
    if (isDeveloper !== 'true' && countryCode !== 'NO-REDIRECT' && isDisabledPath === false && redirectStatus !== 'false') {
        window.location.href = redirectCodes[countryCode]
    }
    if (isDeveloper) {
        setCookie(cookies, true, 'developerSession')
        console.log('Redirect has been stopped, developer session detected. A Cookie has been set to stop redirects, remove it to be able to test redirects again')
    }
};

export const ipStackMe = async (cookies) => {
    try {
        const response = await fetch('https://api.ipify.org/?format=json');
        const { ip } = await response.json();
        const ipStackResponse = await fetch(`https://de2szcy8hb.execute-api.us-west-2.amazonaws.com/geoLocation-IP-stack?ip=${ip}`);
        const data = await ipStackResponse.json();
        const { country_code } = data;
        setCookie(cookies, country_code, 'geolocation')
        handleRedirect(country_code);

    } catch (error) {
        console.log('Error fetching geolocation:', error);
    }
}