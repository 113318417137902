import React, { Component } from "react"
import cn from "classnames"
import { navigate, Link } from "gatsby"
import ScrollLock, { TouchScrollable } from "react-scrolllock"

// Components
import Search from "./search"

// Services
import { supSub } from "../services/util"

// Data
import data from "../data/newHeader.json"

// Styles
import styles from "../styles/scss/components/newMenuMovil.module.scss"

class Nav extends Component {
    state = {
        showHamburger: false,
        items: {},
        level: 1,
        back: data,
        parent: data,
        current: data,
    }

    nodeRef = React.createRef()

    toggleShowHamburger() {
        const { showHamburger, items } = this.state
        this.setState({
            showHamburger: !showHamburger,
            items: showHamburger ? {} : items,
        })
    }

    toogleItems(e, slug, activeBack) {
        const { level, current, back } = this.state
        let stateUpdate = {}
        e.preventDefault()
        e.stopPropagation()

        if (activeBack) {
            let newLevel = level - 1
            stateUpdate = {
                level: newLevel,
                back: newLevel === 2 ? data : current,
                current: back,
            }
        } else {
            let item

            if (level === 1) {
                item = data.find(x => x.slug === slug)

                if (item.children.length === 0) {
                    navigate(item.url)
                }
            } else {
                if (current.length) {
                    let n1 = current[0]
                    if (n1.children)
                        item = n1.children.find(x => x.slug === slug)
                }
            }

            stateUpdate = {
                level: level + 1,
                back: current,
                current: [item],
            }
        }

        this.setState(stateUpdate)
    }

    render() {
        const { current, level, showHamburger } = this.state
        const activeBack = level > 1 ? true : false

        return (
            <div className={styles.menuFull} ref={this.nodeRef}>
                {/* Logo */}
                <div className={styles.logoMenu}>
                    <Link to="/">
                        <img
                            className={styles.menuLogo}
                            src="/img/logo.svg"
                            height="100"
                            alt="WD-40 Logo"
                        />
                    </Link>
                    <div
                        tabIndex={0}
                        role="button"

                        className={cn(styles.menuHamburger, {
                            [styles.activeHamburger]: this.state.showHamburger,
                        })}
                        onKeyDown={(e) => {
                            this.toggleShowHamburger.bind(this)
                        }}
                        onClick={this.toggleShowHamburger.bind(this)}
                    >
                        <img
                            src="/img/hamburger.svg"
                            alt="Hamburger SVG"
                            className={styles.burger}
                        />
                    </div>
                </div>

                {/* Scroll Lock */}
                {showHamburger && <ScrollLock />}

                {showHamburger && (
                    <TouchScrollable>
                        <div
                            className={cn(styles.menuItems, {
                                [styles.active]: this.state.showHamburger,
                            })}
                        >
                            {current.map((item, key) => (
                                <div
                                    id={"menu-" + item.id}
                                    className={cn(
                                        styles.menuItem,
                                        styles[`level-${level}`]
                                    )}
                                    key={key}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                        onKeyDown={e =>
                                            this.toogleItems(
                                                e,
                                                item.slug,
                                                activeBack
                                            )
                                        }
                                        onClick={e =>
                                            this.toogleItems(
                                                e,
                                                item.slug,
                                                activeBack
                                            )
                                        }
                                        className={cn(
                                            styles.topShelf,
                                            styles[`level-${level}-a`],
                                            {
                                                [`${styles.priceSpider}`]:
                                                    item.slug === "buy-now",
                                            },
                                            { "d-sm-none": item.mobileOnly },
                                            { "d-block": item.mobileOnly },
                                            {
                                                [`${styles.subMenuMobielOnly}`]: item.mobileOnly,
                                            }
                                        )}
                                        {...item.data}
                                    >
                                        <i
                                            className={cn(
                                                activeBack
                                                    ? "fa fa-angle-left"
                                                    : "",
                                                activeBack
                                                    ? styles.moveLeft
                                                    : ""
                                            )}
                                        />
                                        {item.title}
                                        {item.children &&
                                            item.children.length > 0 && (
                                                <i
                                                    className={cn(
                                                        level > 1
                                                            ? ""
                                                            : "fa fa-angle-right",
                                                        level > 1
                                                            ? ""
                                                            : styles[
                                                                  "angleOpacity"
                                                              ]
                                                    )}
                                                />
                                            )}
                                    </div>

                                    {item.children && item.children.length > 0 && (
                                        <div
                                            className={cn(
                                                item.children[0].children.length
                                                    ? styles.menuSubAlt
                                                    : "",
                                                styles.menuSub,
                                                activeBack && styles.active
                                            )}
                                        >
                                            {item.children.map(
                                                (subItem, subKey) => {
                                                    return subItem.children &&
                                                        subItem.children
                                                            .length > 0 ? (
                                                        <div
                                                            key={subKey}
                                                            className={cn(
                                                                styles.subItemsMenu,
                                                                styles.menuSub,
                                                                styles.spaceBorder
                                                            )}
                                                        >
                                                            <a
                                                                key={subItem.id}
                                                                href={
                                                                    subItem.url
                                                                }
                                                                target={
                                                                    subItem.openInNewWindow
                                                                        ? "_new"
                                                                        : "_self"
                                                                }
                                                                className={cn(
                                                                    {
                                                                        "d-sm-none": subItem.mobileOnly,
                                                                    },
                                                                    {
                                                                        [`${styles.subMenuMobielOnly}`]: subItem.mobileOnly,
                                                                    }
                                                                )}
                                                                onClick={e =>
                                                                    this.toogleItems(
                                                                        e,
                                                                        subItem.slug,
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.spanWithSupSub
                                                                    }
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: supSub(
                                                                            subItem.title
                                                                        ),
                                                                    }}
                                                                />
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <a
                                                            key={subItem.id}
                                                            href={subItem.url}
                                                            target={
                                                                subItem.openInNewWindow
                                                                    ? "_new"
                                                                    : "_self"
                                                            }
                                                            className={cn(
                                                                {
                                                                    "d-sm-none":
                                                                        subItem.mobileOnly,
                                                                },
                                                                {
                                                                    [`${styles.subMenuMobielOnly}`]: subItem.mobileOnly,
                                                                },
                                                                styles.spaceBorder,
                                                                "spaceBorderBottom"
                                                            )}
                                                        >
                                                            <span
                                                                className={
                                                                    styles.spanWithSupSub
                                                                }
                                                                dangerouslySetInnerHTML={{
                                                                    __html: supSub(
                                                                        subItem.title
                                                                    ),
                                                                }}
                                                            />
                                                        </a>
                                                    )
                                                }
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </TouchScrollable>
                )}

                {/* Search */}
                <Search onlySearch={true} />
            </div>
        )
    }
}

export default Nav
