import React, { useState, useRef } from "react"
import Imgix from "react-imgix"
import Button from "../components/button"
import LoadingOverlay from "react-loading-overlay"
import Modal from "react-responsive-modal"
import Slider from "react-slick"
import YouTube from "react-youtube"
import styles from "../styles/scss/components/galleryModal.module.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const GalleryModal = ({
    images,
    videos,
    isModalOpen,
    setIsModalOpen,
    currentIndex = 0,
    setCurrentIndex,
}) => {
    let gallerySlider = useRef(null)

    // STATES
    const [isLoading, setIsLoading] = useState(false)

    // DEFAULTS
    const slidesCount = videos ? images.length + videos.length : images.length
    const PREVIOUS = "PREVIOUS"
    const NEXT = "NEXT"
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 2000,
        fade: true,
        arrows: false,
    }

    const handlerPreviousNext = (slider, type) => {
        if (type === PREVIOUS) {
            slider.current.slickPrev()
            setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)
        } else {
            slider.current.slickNext()
            setCurrentIndex(
                currentIndex < slidesCount ? currentIndex + 1 : slidesCount
            )
        }
    }

    const hasNext = (current, max) => {
        return current < max
            ? styles.gallerySliderControls_previous_hasNext
            : ""
    }

    const hasPrev = current => {
        return current > 0 ? styles.gallerySliderControls_previous_hasPrev : ""
    }

    return (

        isModalOpen &&
        <Modal
            classNames={{ 
                modal: styles.modal, 
                overlay: styles.modalOverlay,
                closeIcon: styles.closeIcon,
                closeButton: styles.closeButton,
                modalContainer: styles.modalContainer,
            }}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <LoadingOverlay
                active={isLoading}
                spinner={
                    <Imgix
                        className={styles.galleryLogo}
                        src="/img/logo.svg"
                        height="100"
                        alt="WD-40 Logo"
                        htmlAttributes={{alt: 'WD-40 Logo'}}
                    />
                }
                text="Loading WD-40 Gallery..."
            >
                <Slider
                    initialSlide={currentIndex || 0}
                    ref={gallerySlider}
                    className={styles.gallerySlider}
                    onInit={() => {
                        setIsLoading(false)
                    }}
                    {...settings}
                >
                    {images &&
                        images.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    styles.galleryMainWrapper_images_container
                                }
                            >
                                <img
                                    className={
                                        styles.galleryMainWrapper_images_item
                                    }
                                    src={item.url || item.media.url}
                                    alt={`slide product #${index}`}
                                />
                            </div>
                        ))}
                    {videos &&
                        videos.map(v => (
                            <div className={styles.modalVideoContainer}>
                                <YouTube
                                    videoId={v.video.providerUid}
                                    opts={{
                                        height: "390",
                                        width: "100%",
                                        playerVars: {
                                            autoplay: 0,
                                            rel: 0,
                                        },
                                    }}
                                />
                            </div>
                        ))}
                </Slider>
            </LoadingOverlay>

            <div className={styles.gallerySliderControls}>
                <Button
                    className={`${hasPrev(currentIndex)} ${styles.gallerySliderControls_previous
                        }`}
                    text={PREVIOUS}
                    onClick={() => {
                        handlerPreviousNext(gallerySlider, PREVIOUS)
                    }}
                />
                <Button
                    className={` ${hasNext(currentIndex, slidesCount)} ${styles.gallerySliderControls_next
                        }`}
                    text={NEXT}
                    onClick={() => {
                        handlerPreviousNext(gallerySlider, NEXT)
                    }}
                />
            </div>
        </Modal>


    )
}

export default GalleryModal
