import React from 'react';
import Tooltip from 'react-tooltip-lite';

const TooltipComponent = ({ content, direction, tagName, children }) => {
    return <Tooltip
        content={content}
        direction={direction}
        tagName={tagName}
        arrowSize={14}
        padding={0}
        tipContentClassName=""
        distance={26}
    >
        {children}
    </Tooltip >
}

export default TooltipComponent;
